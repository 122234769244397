import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]

  connect() {
    this.observer = new MutationObserver(this.handleMutation.bind(this))
    this.observer.observe(this.element, { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  handleMutation() {
    // Update links to maintain Turbo Frame behavior
    this.element.querySelectorAll('.pagination a').forEach(link => {
      const url = new URL(link.href)
      url.searchParams.set('format', 'html')
      link.href = url.toString()
    })
  }
} 